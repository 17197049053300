import Link from "next/link";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { commentModalHide, commentModalShow } from "../../redux/counterSlice";
import { Confirm_checkout } from "../metamask/Metamask";
import Image from "next/image";
import { timeAgo } from "../../utils/filters";

const CommentModal = () => {
  const dispatch = useDispatch();
  const { commentModal } = useSelector((state) => state.counter);
  const { commentData } = useSelector((state) => state.counter);
  const [comment, setComment] = useState(null);
  const [loading, setLoading] = useState(true);
  const [reply, setReply] = useState(false);

  const [replylist, setReplyList] = useState([]);
  const [commenttext, setCommentText] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();

    setReplyList(prevList => [...prevList, commenttext]);

    setCommentText("");
    setReply(false)
  }

  useEffect(() => {
    const getAllSocial = async () => {
      try {
        if (commentData != null) {
          const requestOptions = {
            method: 'GET',
            redirect: 'follow',
          };

          const response = await fetch(
            `https://devapi.impactoverse.com/api/gallery/posts/comment/${commentData}`,
            requestOptions
          );

          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          const result = await response.json();
          setComment(result.data.comments);
          setLoading(false);
          // console.log(result.data.comments)
        }
        // console.log(commentData)
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }

    };

    getAllSocial();
    // Simulate data loading delay

  }, [commentData]);

  return (
    <div>
      {/* <!-- Buy Now Modal --> */}
      <div className={commentModal ? "modal fade show block" : "modal fade"}>
        <div className="modal-dialog max-w-2xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="buyNowModalLabel">
                Comments
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => dispatch(commentModalHide())}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="fill-jacarta-700 h-6 w-6 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                </svg>
              </button>
            </div>

            {/* <!-- Body --> */}
            <div className="modal-body p-6">
              {/* <div className="mb-2 flex items-center justify-between">
                <span className="font-display text-jacarta-700 text-sm font-semibold dark:text-white">
                  Item
                </span>
                <span className="font-display text-jacarta-700 text-sm font-semibold dark:text-white">
                  Subtotal
                </span>
              </div> */}

              {/* <div className="dark:border-jacarta-600 border-jacarta-100 relative flex items-center border-t border-b py-4"> */}
              {/* <figure className="mr-5 self-start">
                  <Image
                    width={150}
                    height={150}
                    src="/images/avatars/avatar_2.jpg"
                    alt="avatar 2"
                    className="rounded-2lg"
                    loading="lazy"
                  />
                </figure> */}

              {/* <div> */}
              {/* <a href="collection.html" className="text-accent text-sm">
                    Elon Musk #709
                  </a>
                  <h3 className="font-display text-jacarta-700 mb-1 text-base font-semibold dark:text-white">
                    Lazyone Panda
                  </h3> */}
              {/* <div className="flex flex-wrap items-center">
                    <span className="dark:text-jacarta-300 text-jacarta-500 mr-1 block text-sm">
                      Creator Earnings: 5%
                    </span>
                    <span data-tippy-content="The creator of this collection will receive 5% of the sale total from future sales of this item.">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        className="dark:fill-jacarta-300 fill-jacarta-700 h-4 w-4"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM11 7h2v2h-2V7zm0 4h2v6h-2v-6z" />
                      </svg>
                    </span>
                  </div> */}
              {/* </div> */}

              {/* <div className="ml-auto">
                  <span className="mb-1 flex items-center whitespace-nowrap">
                    <span data-tippy-content="ETH">
                      <svg className="h-4 w-4">
                        <use xlinkHref="/icons.svg#icon-ETH"></use>
                      </svg>
                    </span>
                    <span className="dark:text-jacarta-100 text-sm font-medium tracking-tight">
                      1.55 ETH
                    </span>
                  </span>
                  <div className="dark:text-jacarta-300 text-right text-sm">
                    $130.82
                  </div>
                </div> */}
              {/* </div> */}
              {loading ? '' : comment?.map((data) => {
                const { _id, comment, createdAt } = data;
                const dateTime = new Date(createdAt);
                const humanTime = timeAgo(dateTime);
                return <div key={_id}>
                  <h3 className="font-display text-jacarta-700 mb-1 text-base font-semibold dark:text-white">
                    {comment}
                  </h3>

                  {/* <span className="text-jacarta-300 block text-xs">2 hrs</span> */}
                  <div className="text-jacarta-400 flex flex-wrap items-center space-x-2 text-sm">
                    <span>
                      <time>{humanTime}</time>
                    </span>
                    <span>•</span>

                    <button
                      className="text-accent flex flex-wrap items-center space-x-2 text-sm"
                      onClick={() => {
                        setReply(!reply)
                      }}
                    >
                      Reply
                    </button>
                  </div>
                </div>
              })}
              <ul>
                {replylist?.map((comment, index) => {
                  const dateTime = Date.now();
                  const humanTime = timeAgo(dateTime);
                  return <div key={index} className="mt-5 ms-10">
                    <h3 className="font-display text-jacarta-700 mb-1 text-base font-semibold dark:text-white">
                      {comment}
                    </h3>

                    {/* <span className="text-jacarta-300 block text-xs">2 hrs</span> */}
                    <div className="text-jacarta-400 flex flex-wrap items-center space-x-2 text-sm">
                      <span>
                        <time>{humanTime}</time>
                      </span>
                      <span>•</span>

                      <button
                        className="text-accent flex flex-wrap items-center space-x-2 text-sm"
                        onClick={() => {
                          setReply(!reply)
                        }}
                      >
                        Reply
                      </button>
                    </div>
                  </div>
                })}
              </ul>
              {reply ? <div className="mt-5 ms-10">
                <form onSubmit={handleSubmit}>
                  <input type="text" id="item-name" className="dark:bg-jacarta-700 border-jacarta-100 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 px-3 hover:ring-2 dark:text-white" placeholder="Please enter your Reply comment here" required=""
                    value={commenttext}
                    onChange={(e) => setCommentText(e.target.value)}
                  />
                  <div className="flex items-center justify-center space-x-4 ms-20">
                    <button type="submit" class="bg-accent shadow-accent-volume hover:bg-accent-dark rounded-full py-3 px-8 text-center font-semibold text-white transition-all mt-4"> Reply COMMENT</button>
                  </div>
                </form>
              </div> : ''}

              {/* <div className="dark:border-jacarta-600 border-jacarta-100 ml-auto rounded-full border p-3">
               
              </div> */}
              {/* <!-- Total --> */}
              {/* <div className="dark:border-jacarta-600 border-jacarta-100 mb-2 flex items-center justify-between border-b py-2.5">
                <span className="font-display text-jacarta-700 hover:text-accent font-semibold dark:text-white">
                  Total
                </span>
                <div className="ml-auto">
                  <span className="flex items-center whitespace-nowrap">
                    <span data-tippy-content="ETH">
                      <svg className="h-4 w-4">
                        <use xlinkHref="/icons.svg#icon-ETH"></use>
                      </svg>
                    </span>
                    <span className="text-green font-medium tracking-tight">
                      1.55 ETH
                    </span>
                  </span>
                  <div className="dark:text-jacarta-300 text-right">
                    $130.82
                  </div>
                </div>
              </div> */}

              {/* <!-- Terms --> */}
              {/* <div className="mt-4 flex items-center space-x-2">
                <input
                  type="checkbox"
                  id="buyNowTerms"
                  className="checked:bg-accent dark:bg-jacarta-600 text-accent border-jacarta-200 focus:ring-accent/20 dark:border-jacarta-500 h-5 w-5 self-start rounded focus:ring-offset-0"
                />
                <label
                  htmlFor="buyNowTerms"
                  className="dark:text-jacarta-200 text-sm"
                >
                  By checking this box, I agree to {"Impactoverse's"}{" "}
                  <Link href="/terms-of-service" className="text-accent">
                    Terms of Service
                  </Link>
                </label>
              </div> */}
            </div>
            {/* <!-- end body --> */}

            <div className="modal-footer">
              <input
                type="text"
                id="item-name"
                className="dark:bg-jacarta-700 border-jacarta-100 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 px-3 hover:ring-2 dark:text-white"
                placeholder="Please enter your comment here"
                required
              />
              <div className="flex items-center justify-center space-x-4">
                <button
                  className="bg-accent shadow-accent-volume hover:bg-accent-dark rounded-full py-3 px-8 text-center font-semibold text-white transition-all mt-4"
                  onClick={() => dispatch(buyModalShow())}
                >
                  COMMENT
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommentModal;

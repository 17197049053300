import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

const LoadingOverlay = () => {
  const router = useRouter();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleStart = () => setLoading(true);
    const handleComplete = () => setLoading(false);

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleComplete);
    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
      router.events.off('routeChangeError', handleComplete);
    };
  }, [router]);

  return  (
    <div className="fixed inset-0 flex bg-gray-800 bg-opacity-75 z-50 " style={{top:'50%',left:"48%"}} id='overlay'>
      <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
    </div>
  ) ;
};

export default LoadingOverlay;

const footerMenuList = [
  {
    id: 1,
    title: "CHECK IT OUT",
    diffClass: "md:col-start-7",
    list: [
      // {
      //   id: 1,
      //   href: "#",
      //   text: "Impact Network",
      // },
      {
        id: 2,
        href: "/about",
        text: "About",
      },
      {
        id: 3,
        href: "/faqs",
        text: "FAQs",
      },
      {
        id: 4,
        href: "/privacy-policy",
        text: "Privacy",
      },
    ],
  },
  {
    id: 2,
    title: "FIND OUT MORE",
    diffClass: "",
    list: [
      {
        id: 1,
        href: "/world-of-impact",
        text: "World of impact",
      },
      {
        id: 2,
        href: "https://impactdots.world/",
        text: "Impact Campaigns",
      },
      // {
      //   id: 3,
      //   href: "/contact",
      //   text: "Contact Us",
      // },
      // {
      //   id: 4,
      //   href: "#",
      //   text: "Our Blog",
      // },
      // {
      //   id: 5,
      //   href: "#",
      //   text: "FAQs",
      // },
    ],
  },
];

const socialIcons = [
  {
    id: 1,
    href: "https://www.facebook.com/share/zhHHfJTyLndA6zKj/?mibextid=LQQJ4d",
    text: "facebook",
  },
  {
    id: 2,
    href: "https://twitter.com/impactoverse?s=21&t=724Qr7Mq2mqAnBIVRDYe4w",
    text: "twitter",
  },
  // {
  //   id: 3,
  //   href: "https://www.discord.com",
  //   text: "discord",
  // },
  {
    id: 4,
    href: "https://www.instagram.com/impactoverse?igshid=YmMyMTA2M2Y%3D",
    text: "instagram",
  },
  {
    id: 5,
    href: "https://www.tiktok.com/@impactoverse/",
    text: "tiktok",
  },
];

export { footerMenuList, socialIcons };

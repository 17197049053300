import "../styles/globals.css";
import { ThemeProvider } from "next-themes";
import Layout from "../components/layout";
import { Provider } from "react-redux";
import { store } from "../redux/store";
import { useRouter } from "next/router";
import { MetaMaskProvider } from "metamask-react";
import Meta from "../components/Meta";
import UserContext from "../components/UserContext";
import { useRef } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import LoadingOverlay from "./LoadingOverlay";

if (typeof window !== "undefined") {
  require("bootstrap/dist/js/bootstrap");
}

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const pid = router.asPath;
  const scrollRef = useRef({
    scrollPos: 0,
  });

  // Your logic to determine if the user is logged in
  const isLoggedIn = true;

  let renderedComponent;

  if (pid === "/login" && isLoggedIn) {
    renderedComponent = <Component {...pageProps} />;
  } else if (
    pid === "/sign-in" ||
    pid === "/sign-up" ||
    pid === "/forget-password" ||
    pid === "/change-password" ||
    pid.includes('/dashboard') ||
    (pid.includes('/otp-validation'))
  ) {
    renderedComponent = <Component {...pageProps} />;
  } else {
    renderedComponent = (
      <Layout>
        <Component {...pageProps} />
      </Layout>
    );
  }

  return (
    <>
      <Meta title="Home" />

      <Provider store={store}>
        <ThemeProvider enableSystem={true} attribute="class">
          <MetaMaskProvider>
            <ToastContainer />
            <UserContext.Provider value={{ scrollRef: scrollRef }}>
              {renderedComponent}
              <LoadingOverlay />
            </UserContext.Provider>
          </MetaMaskProvider>
        </ThemeProvider>
      </Provider>
    </>
  );
}

export default MyApp;
